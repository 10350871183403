import React from 'react'
import styled from 'styled-components'

interface LogoProps {
  fill?: string
  width?: string
  height?: string
}

const StyledLogo = styled.div``

export const NewFoundLogo = (props: LogoProps) => {
  return (
    <StyledLogo className="found-logo__wrapper">
      <svg
        width={props.width ? props.width : '115'}
        height={props.height ? props.height : '32'}
        viewBox="0 0 115 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0)">
          <path
            d="M10.464 0.00167562C6.34694 0.00167562 3.01571 2.6847 3.01571 6.51323C3.01571 8.99497 4.61773 10.0489 6.65306 10.6223C7.30331 10.8054 7.30331 11.6252 6.65306 11.8083C4.74106 12.3469 3.00775 13.3661 3.00775 15.457L3.00766 27.909C3.00766 29.5854 2.30557 30.1866 0.398096 30.4491C0.172195 30.4802 0 30.6704 0 30.8986V31.1805C0 31.427 0.199674 31.6251 0.445976 31.6251H10.0604C10.3067 31.6251 10.5063 31.4253 10.5063 31.1789V30.8995C10.5063 30.6702 10.3322 30.4793 10.1049 30.4502C8.06476 30.1892 7.3577 29.5897 7.3577 27.9073C7.3577 25.6523 7.35786 18.5901 7.35804 14.7788C7.35812 12.9726 10.3455 12.0206 11.5119 11.8223C12.2544 11.696 12.2544 10.6286 11.5118 10.5024C8.15916 9.93247 6.07324 7.72044 6.07324 5.22959C6.3621 2.34829 7.89482 1.5195 9.58666 1.5195C11.9804 1.5195 12.828 4.08399 13.1977 5.20262C13.22 5.26993 13.2405 5.33201 13.2595 5.38797C13.4564 5.82465 13.9457 6.35578 14.4658 6.35578H15.3938C16.4747 6.35578 17.6025 5.78853 17.6025 4.32963C17.6025 2.17795 15.2348 0.0260322 11.3299 0L10.464 0.00167562Z"
            fill={props.fill ? props.fill : '#D2DDA1'}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M111.7 2.30369V28.0938C111.7 29.5731 112.383 30.2077 114.231 29.8117C114.472 29.7601 114.708 29.9378 114.708 30.1845V30.618C114.708 30.7459 114.642 30.8656 114.533 30.9314C113.193 31.7368 111.984 31.9529 110.948 31.9529C108.739 31.9529 107.47 30.9646 107.47 28.3291C105.45 30.8234 102.865 32 100.045 32C94.9225 32 90.7399 27.9056 90.7399 21.3639C90.7399 14.1163 95.6744 10.069 100.985 10.069C102.963 10.069 104.438 10.6072 105.478 10.9864C106.858 11.4902 107.47 11.7134 107.47 10.0219V5.7863C107.47 3.83414 106.969 2.34371 104.825 2.45303C104.605 2.46421 104.416 2.29308 104.416 2.07308V1.70326C104.416 1.52693 104.539 1.37465 104.711 1.3381L109.961 0.891834C111.028 0.891834 111.7 1.1742 111.7 2.30369ZM95.1105 19.4814C95.1105 24.9407 97.9772 29.2233 102.348 29.2233C104.557 29.2233 107.47 28.0938 107.47 24.3289V18.3049C107.47 13.5986 103.758 11.5279 100.797 11.5279C97.1783 11.5279 95.1105 14.7281 95.1105 19.4814Z"
            fill={props.fill ? props.fill : '#D2DDA1'}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M25.323 32C31.1504 32 36.461 27.9056 36.461 20.9874C36.461 14.1163 31.1974 10.069 25.417 10.069C19.5895 10.069 14.326 14.1634 14.326 21.0816C14.326 27.9526 19.5425 32 25.323 32ZM26.4039 30.5881C21.9863 30.5881 18.7906 25.4583 18.7906 19.4814C18.7906 14.0222 21.1404 11.4808 24.3831 11.4808C28.7537 11.4808 31.9964 16.5636 31.9964 22.5875C31.9964 28.0468 29.5996 30.5881 26.4039 30.5881Z"
            fill={props.fill ? props.fill : '#D2DDA1'}
          />
          <path
            d="M54.6406 28.8468C53.9356 29.6469 51.8208 32 47.8262 32C44.3015 32 40.9648 29.9293 40.9648 24.1877V15.1046C40.9648 13.1081 40.9096 11.6664 38.7653 11.7755C38.5459 11.7867 38.3561 11.6156 38.3561 11.3956V11.0257C38.3561 10.8494 38.4793 10.6971 38.6516 10.6606L43.4614 10.2143C44.5214 10.2143 45.1944 10.4455 45.1944 11.575V23.1523C45.1944 26.7761 46.9803 29.3645 50.27 29.3645C53.5597 29.3645 55.7215 26.6349 55.7215 23.1523V15.1046C55.7215 13.1058 55.7069 11.664 53.5214 11.7755C53.3023 11.7867 53.1127 11.6156 53.1127 11.3959V11.0253C53.1127 10.849 53.236 10.6967 53.4082 10.6602L58.2904 10.2139C59.3721 10.2139 59.9511 10.4455 59.9511 11.575V28.1409C59.9511 29.5719 60.5901 30.2125 61.8345 30.0626C62.0742 30.0337 62.3009 30.2092 62.3009 30.4509V30.8679C62.3009 30.9878 62.2441 31.1014 62.1432 31.1659C61.3025 31.7036 60.1208 31.9529 59.1052 31.9529C57.0844 31.9529 55.9565 30.8234 55.6745 29.1292C55.6275 28.7527 55.5335 28.4703 55.2045 28.4703C55.0055 28.4703 54.9076 28.5717 54.7395 28.7457L54.7091 28.7771C54.7006 28.7859 54.6919 28.7948 54.683 28.8039C54.6752 28.8119 54.6672 28.82 54.659 28.8283L54.6499 28.8374L54.6406 28.8468Z"
            fill={props.fill ? props.fill : '#D2DDA1'}
          />
          <path
            d="M84.3038 31.6116C85.6682 32.1576 87.6474 32.0006 89.2235 31.1404C89.3276 31.0836 89.39 30.9732 89.39 30.8546V30.3438C89.39 30.126 89.1854 29.968 88.9705 30.0021C87.7107 30.2025 86.9438 29.7032 86.9438 27.7158V17.3538C86.9438 11.5073 83.5711 9.46342 80.0085 9.46342C76.3445 9.46342 74.4884 11.4862 73.593 12.462C73.5495 12.5093 73.5083 12.5542 73.4693 12.5963C73.3705 12.7029 73.2776 12.8052 73.1881 12.9038L73.1879 12.9042L73.1866 12.9056C73.0819 13.0209 72.9819 13.1312 72.8825 13.2369C72.555 13.502 72.1989 13.2369 72.1222 12.6763C72.0355 12.0424 71.805 11.5934 71.5759 11.2511C69.9686 8.85004 66.1492 10.208 65.4873 10.588C65.2974 10.697 65.2169 10.8063 65.2169 10.9844V11.358C65.2169 11.5802 65.4018 11.784 65.6236 11.7727C67.5299 11.7096 67.7529 12.5329 67.7529 14.5494V27.9508C67.7529 29.6629 67.0273 30.2646 65.0501 30.5251C64.8591 30.5502 64.7128 30.7113 64.7128 30.904V31.3288C64.7128 31.537 64.8815 31.7058 65.0895 31.7058H74.6915C74.8996 31.7058 75.0683 31.537 75.0683 31.3288V30.904C75.0683 30.7113 74.9219 30.5502 74.7309 30.5251C72.7537 30.2646 72.0281 29.6629 72.0281 27.9508V18.8412C72.0281 15.3238 74.2132 12.567 77.5384 12.567C80.911 12.567 82.6686 15.2288 82.6686 18.8412V28.2187C82.6686 29.7266 83.1332 31.143 84.3038 31.6116Z"
            fill={props.fill ? props.fill : '#D2DDA1'}
          />
        </g>
        <defs>
          <clipPath id="clip0">
            <rect width="115" height="32" fill="white" transform="translate(0.5)" />
          </clipPath>
        </defs>
      </svg>
    </StyledLogo>
  )
}

export default NewFoundLogo
