import get from 'lodash/get'
import React from 'react'
import styled, { css } from 'styled-components'

// TODO: update styled components type def to easily specify 'color' type
interface ButtonProps {
  margin?: string
  children: React.ReactElement | string
  backgroundColor?: string
  color?: string
  whiteText?: boolean
  disabled?: boolean
  primary?: boolean
  onClick?: () => void
  type: 'button' | 'submit'
  className?: string
}

const StyledButton = styled.button<ButtonProps>`
  border: none;
  border-radius: 20px;
  min-height: 34px;
  height: fit-content;
  letter-spacing: normal;
  padding: 5px 24px;
  text-transform: none;
  margin-right: 10px;
  margin: ${props => (props.margin ? props.margin : '0 10px 0 0')};
  outline: none;
  cursor: pointer;
  font-size: 14px;
  line-height: 15px;
  background-color: ${props =>
    props.backgroundColor ? get(props.theme.colors, `${props.backgroundColor}`) : 'Transparent'};
  color: ${props =>
    props.whiteText
      ? props.theme.colors.white
      : props.color
      ? props.color
      : props.theme.colors.black};
  display: flex;
  justify-content: center;
  align-items: center;
  ${props =>
    props.disabled
      ? css`
          background-color: ${props => props.theme.colors.lightGrey2};
          color: ${props => props.theme.colors.midGrey2};
        `
      : null};
`

const Button = (props: ButtonProps) => <StyledButton {...props}>{props.children}</StyledButton>

export default Button
