import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import upvoty from './upvoty'
import styled from 'styled-components'

const StyledIframe = styled.div`
  position: absolute;
  top: 78px;
  left: 0;
  iframe {
    width: 100vw;
    height: 91vh;
  }
`
const UpvotyWidget = ({
  boardHash,
  ssoToken,
  baseUrl,
}: {
  boardHash: string
  ssoToken: string
  baseUrl: string
}) => {
  useEffect(() => {
    upvoty.init('render', { boardHash, ssoToken, baseUrl })
    return () => {
      upvoty.destroy()
    }
  }, [])
  return <StyledIframe data-upvoty />
}

UpvotyWidget.defaultProps = {
  ssoToken: null,
  boardHash: null,
}

UpvotyWidget.propTypes = {
  boardHash: PropTypes.string,
  ssoToken: PropTypes.string,
  baseUrl: PropTypes.string.isRequired,
}

export default UpvotyWidget
